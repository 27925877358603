
.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list,
.uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 6px;
}
.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list::after,
.uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list::after {
  flex: auto;
  content: "";
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  position: relative;
  width: 50%;
  margin: 0;
}
.uppy-size--md .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem,
.uppy-size--md .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 33.3333%;
}
.uppy-size--lg .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem,
.uppy-size--lg .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 25%;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem::before,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem::before {
  display: block;
  padding-top: 100%;
  content: "";
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected img,
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected svg,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected img,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected svg {
  opacity: 0.85;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--disabled,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--disabled {
  opacity: 0.5;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: rgba(147, 147, 147, 0.2);
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner,
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: rgba(234, 234, 234, 0.2);
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  width: 30%;
  height: 30%;
  fill: rgba(0, 0, 0, 0.7);
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg,
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  fill: rgba(255, 255, 255, 0.8);
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  overflow: hidden;
  text-align: center;
  border-radius: 4px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy.uppy-ProviderBrowserItem-inner-relative,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy.uppy-ProviderBrowserItem-inner-relative {
  position: relative;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  padding: 5px;
  text-decoration: none;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author:hover,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author:hover {
  background: rgba(0, 0, 0, 0.4);
  text-decoration: underline;
}
@media (hover: none) {
  .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
    display: block;
  }
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner,
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  box-shadow: 0 0 0 3px rgba(170, 225, 255, 0.7);
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner img,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1002;
  width: 26px;
  height: 26px;
  background-color: #2275d7;
  border-radius: 50%;
  opacity: 0;
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox::after,[dir="ltr"] 
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox::after{
  left: 7px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox::after,[dir="rtl"] 
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox::after{
  right: 7px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox::after,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox::after {
  top: 8px;
  width: 12px;
  height: 7px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--is-checked,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--is-checked {
  opacity: 1;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author {
  display: block;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus {
  outline: none;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner {
  border: 0;
}

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list {
  background-color: #1f1f1f;
}
.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 7px 15px;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  color: #eaeaea;
}
.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem--disabled {
  opacity: 0.6;
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox{
  margin-right: 15px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox{
  margin-left: 15px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border: 1px solid #2275d7;
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25);
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox::after{
  left: 3px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox::after{
  right: 3px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox::after {
  top: 4px;
  width: 9px;
  height: 5px;
  opacity: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border-color: rgba(2, 186, 242, 0.7);
  box-shadow: 0 0 0 3px rgba(2, 186, 242, 0.2);
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #2275d7;
  border-color: #2275d7;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked::after {
  opacity: 1;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
  display: flex;
  align-items: center;
  padding: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner:focus {
  text-decoration: underline;
  outline: none;
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img,[dir="ltr"] 
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg{
  margin-right: 8px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img,[dir="rtl"] 
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg{
  margin-left: 8px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img,
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  max-width: 20px;
  max-height: 20px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner span {
  overflow: hidden;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem--disabled .uppy-ProviderBrowserItem-inner {
  cursor: default;
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap{
  margin-right: 7px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap{
  margin-left: 7px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  width: 20px;
}

.uppy-ProviderBrowserItem-checkbox {
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}
.uppy-ProviderBrowserItem-checkbox:disabled {
  cursor: default;
}
.uppy-ProviderBrowserItem-checkbox::after {
  position: absolute;
  border-bottom: 2px solid #eaeaea;
  border-left: 2px solid #eaeaea;
  transform: rotate(-45deg);
  cursor: pointer;
  content: "";
}
.uppy-ProviderBrowserItem-checkbox:disabled::after {
  cursor: default;
}
[data-uppy-theme=dark] .uppy-ProviderBrowserItem-checkbox {
  background-color: #1f1f1f;
  border-color: #939393;
}

[data-uppy-theme=dark] .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #333;
}

.uppy-SearchProvider {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
[data-uppy-theme=dark] .uppy-SearchProvider {
  background-color: #1f1f1f;
}

.uppy-SearchProvider-input {
  width: 90%;
  max-width: 650px;
  margin-bottom: 15px;
}
.uppy-size--md .uppy-SearchProvider-input {
  margin-bottom: 20px;
}

.uppy-SearchProvider-searchButton {
  padding: 13px 25px;
}
.uppy-size--md .uppy-SearchProvider-searchButton {
  padding: 13px 30px;
}

.uppy-DashboardContent-panelBody {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
[data-uppy-theme=dark] .uppy-DashboardContent-panelBody {
  background-color: #1f1f1f;
}

.uppy-Provider-auth,
.uppy-Provider-error,
.uppy-Provider-loading,
.uppy-Provider-empty {
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  color: #939393;
}

.uppy-Provider-empty {
  color: #939393;
}

.uppy-Provider-authIcon svg {
  width: 100px;
  height: 75px;
  margin-bottom: 15px;
}

.uppy-Provider-authTitle {
  max-width: 500px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #757575;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.4;
  text-align: center;
}
.uppy-size--md .uppy-Provider-authTitle {
  font-size: 20px;
}
[data-uppy-theme=dark] .uppy-Provider-authTitle {
  color: #cfcfcf;
}

.uppy-Provider-btn-google {
  display: flex;
  align-items: center;
  padding: 8px 12px !important;
  background: #4285F4;
}
.uppy-Provider-btn-google:hover {
  background-color: #1266f1;
}
.uppy-Provider-btn-google:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.4);
}
.uppy-Provider-btn-google svg {
  margin-right: 8px;
}

[dir="ltr"] .uppy-Provider-breadcrumbs{
  text-align: left;
}

[dir="rtl"] .uppy-Provider-breadcrumbs{
  text-align: right;
}

.uppy-Provider-breadcrumbs {
  flex: 1;
  margin-bottom: 10px;
  color: #525252;
  font-size: 12px;
}
.uppy-size--md .uppy-Provider-breadcrumbs {
  margin-bottom: 0;
}
[data-uppy-theme=dark] .uppy-Provider-breadcrumbs {
  color: #eaeaea;
}

[dir="ltr"] .uppy-Provider-breadcrumbsIcon{
  margin-right: 4px;
}

[dir="rtl"] .uppy-Provider-breadcrumbsIcon{
  margin-left: 4px;
}

.uppy-Provider-breadcrumbsIcon {
  display: inline-block;
  color: #525252;
  line-height: 1;
  vertical-align: middle;
}

.uppy-Provider-breadcrumbsIcon svg {
  width: 13px;
  height: 13px;
  fill: #525252;
}

.uppy-Provider-breadcrumbs button {
  display: inline-block;
  padding: 4px;
  line-height: inherit;
  border-radius: 3px;
}
.uppy-Provider-breadcrumbs button:focus {
  outline: none;
}
.uppy-Provider-breadcrumbs button::-moz-focus-inner {
  border: 0;
}
.uppy-Provider-breadcrumbs button:hover {
  color: #1b5dab;
}
.uppy-Provider-breadcrumbs button:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-Provider-breadcrumbs button:focus {
  background-color: #333;
}
.uppy-Provider-breadcrumbs button:not(:last-of-type) {
  text-decoration: underline;
}
.uppy-Provider-breadcrumbs button:last-of-type {
  color: #333;
  font-weight: 500;
  cursor: normal;
  pointer-events: none;
}
.uppy-Provider-breadcrumbs button:hover {
  cursor: pointer;
}
[data-uppy-theme=dark] .uppy-Provider-breadcrumbs button {
  color: #eaeaea;
}

.uppy-ProviderBrowser {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
}

.uppy-ProviderBrowser-user {
  margin: 0 8px 0 0;
  color: #333;
  font-weight: 500;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-user {
  color: #eaeaea;
}

[dir="ltr"] .uppy-ProviderBrowser-user::after{
  left: 4px;
}

[dir="rtl"] .uppy-ProviderBrowser-user::after{
  right: 4px;
}

.uppy-ProviderBrowser-user::after {
  position: relative;
  color: #939393;
  font-weight: normal;
  content: "·";
}

.uppy-ProviderBrowser-header {
  position: relative;
  z-index: 1001;
  border-bottom: 1px solid #eaeaea;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-header {
  border-bottom: 1px solid #333;
}

.uppy-ProviderBrowser-headerBar {
  z-index: 1001;
  padding: 7px 15px;
  color: #757575;
  font-size: 12px;
  line-height: 1.4;
  background-color: #fafafa;
}
.uppy-size--md .uppy-ProviderBrowser-headerBar {
  display: flex;
  align-items: center;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-headerBar {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-headerBar--simple {
  display: block;
  justify-content: center;
  text-align: center;
}

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
  display: inline-block;
  flex: none;
  vertical-align: middle;
}

.uppy-ProviderBrowser-filter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #fff;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-filter {
  background-color: #1f1f1f;
}

[dir="ltr"] .uppy-ProviderBrowser-filterIcon{
  left: 16px;
}

[dir="rtl"] .uppy-ProviderBrowser-filterIcon{
  right: 16px;
}

.uppy-ProviderBrowser-filterIcon {
  position: absolute;
  z-index: 1002;
  width: 12px;
  height: 12px;
  color: #bbb;
}

[dir="ltr"] .uppy-ProviderBrowser-filterInput{
  padding-left: 27px;
}

[dir="rtl"] .uppy-ProviderBrowser-filterInput{
  padding-right: 27px;
}

.uppy-ProviderBrowser-filterInput {
  z-index: 1001;
  width: 100%;
  height: 30px;
  margin: 0 8px;
  font-size: 12px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Symbol", "Segoe UI Emoji", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.4;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-filterInput {
  color: #eaeaea;
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-filterInput:focus {
  background-color: #f4f4f4;
  outline: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-filterInput:focus {
  background-color: #333;
}

.uppy-ProviderBrowser-filterInput::placeholder {
  color: #939393;
  opacity: 1;
}

.uppy-ProviderBrowser-search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: 2px;
  margin-bottom: 2px;
}

[dir="ltr"] .uppy-ProviderBrowser-searchInput{
  padding-left: 30px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchInput{
  padding-right: 30px;
}

.uppy-ProviderBrowser-searchInput {
  z-index: 1001;
  width: 100%;
  height: 30px;
  font-size: 13px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Symbol", "Segoe UI Emoji", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.4;
  background-color: #eaeaea;
  border: 0;
  border-radius: 4px;
  outline: 0;
  color: #333;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-searchInput {
  color: #eaeaea;
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-searchInput:focus {
  background-color: #cfcfcf;
  outline: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-searchInput:focus {
  background-color: #333;
}

[dir="ltr"] .uppy-ProviderBrowser-searchIcon{
  left: 10px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchIcon{
  right: 10px;
}

.uppy-ProviderBrowser-searchIcon {
  position: absolute;
  z-index: 1002;
  width: 12px;
  height: 12px;
  color: #757575;
}

.uppy-ProviderBrowser-searchInput::placeholder {
  color: #939393;
  opacity: 1;
}

.uppy-ProviderBrowser-userLogout {
  padding: 4px;
  color: #2275d7;
  line-height: inherit;
  border-radius: 3px;
  cursor: pointer;
}
.uppy-ProviderBrowser-userLogout:focus {
  outline: none;
}
.uppy-ProviderBrowser-userLogout::-moz-focus-inner {
  border: 0;
}
.uppy-ProviderBrowser-userLogout:hover {
  color: #1b5dab;
}
.uppy-ProviderBrowser-userLogout:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-userLogout:focus {
  background-color: #333;
}
.uppy-ProviderBrowser-userLogout:hover {
  text-decoration: underline;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-userLogout {
  color: #eaeaea;
}

.uppy-ProviderBrowser-body {
  position: relative;
  flex: 1;
}

.uppy-ProviderBrowser-list {
  // position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  background-color: #fff;
  border-spacing: 0;
  -webkit-overflow-scrolling: touch;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-list {
  background-color: #1f1f1f;
}
.uppy-ProviderBrowser-list:focus {
  outline: none;
}

.uppy-ProviderBrowserItem-inner {
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}

.uppy-ProviderBrowser-footer {
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 15px;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
}
[dir="ltr"] .uppy-ProviderBrowser-footer button{
  margin-right: 8px;
}
[dir="rtl"] .uppy-ProviderBrowser-footer button{
  margin-left: 8px;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-footer {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}