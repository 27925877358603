// App Global CSS
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply globally. These styles are for
// the entire app and not just one component. Additionally, this file can be
// also used as an entry point to import other Sass files to be included in the
// output CSS.

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "~@uppy/core/dist/style.css";
@import "./app/uppy.scss";

@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light-border.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/themes/material.css';
@import 'tippy.js/themes/translucent.css';

@import 'vidstack/player/styles/base.css';
@import 'vidstack/player/styles/default/theme.css';
@import 'vidstack/player/styles/default/layouts/audio.css';
@import 'vidstack/player/styles/default/layouts/video.css';


/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

 @import '@ionic/angular/css/palettes/dark.class.css';
//  @import '@ionic/angular/css/palettes/high-contrast.class.css';

// App CSS
// ----------------------------------------------------------------------------

@import "./app/app.scss";

@import url('./assets/lib/noto-emoji/font/noto-color-emoji.css');

.popover-primary {
  --background: #005C72;
}

.popover-emoji-tablet {
  --width: 352px;
  --height: 62px;
  --background: white;
}

.popover-emoji-smartphone {
  --width: 288px;
  --height: 52px;
  --background: white;
}

.toast-dark {
  --button-color: white;
}

.animoji {
  width: 93px !important;
  height: 93px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.animoji-no-animation {
  margin-top: 8px !important;
  margin-right: 8px !important;
}

.custom-modal {
  --height: 80%;
  --width: 60%;
  --max-width: 900px;
  --border-radius: 12px;
}

.custom-modal-system-notifications {
  align-items: end;
  justify-content: start;
  padding-left: 77px;
  padding-bottom: 192px;
  --min-height: 100%;
  --height: 100%;
  --border-radius: 0px;
  --width: 443px;
  --max-width: 443px;
  padding-bottom: 0px;
  --backdrop-opacity: 0%;
}

.no-ripple {
  --ripple-color: transparent;
}

.action-sheet-round-border .action-sheet-group:first-child {
  border-radius: 12px 12px 0px 0px;
}

ion-chip {
  pointer-events: none;
}

ion-button {
  --box-shadow: none;
  text-transform: none;
}

// ion-input {
//   --highlight-color-focused: transparent !important;
// }

// ion-fab-button {
//   --box-shadow: none;
// }

.ProseMirror:focus {
  outline: none;
}

// .ProseMirror {
//   white-space: nowrap !important;
// }

.editor-paragraph {
  font-size: 18px;
  letter-spacing: 0.35px;
  line-height: 32px;
  font-weight: 400;
  display: inline !important;
}

.editor-list-item {
  margin-top: 10px;
  margin-bottom: 24px;
  padding-right: 16px;
  border-right: 2px solid lightgray;
}

.editor-list-item-reviewed {
  margin-top: 10px;
  margin-bottom: 24px;
  border-right: 2px solid #005C72;
  padding-right: 16px;
}

.collaboration-cursor__caret {
  position: relative;
  margin-left: -1px;
  margin-right: -1px;
  border-left: 1px solid #0D0D0D;
  border-right: 1px solid #0D0D0D;
  word-break: normal;
  pointer-events: none;
}

.collaboration-cursor__label {
  position: absolute;
  top: -1.4em;
  left: -1px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  user-select: none;
  color: #0D0D0D;
  padding: 0.1rem 0.3rem;
  border-radius: 3px 3px 3px 0;
  white-space: nowrap;
}

.tooltipClass {
  padding: 10px !important;
  background: #545454 !important;
}

.tooltipClass.tooltip-top::after {
  border-color: #545454 transparent transparent transparent !important;
}

.tooltipClass.tooltip-bottom::after {
  border-color: transparent transparent #545454 transparent !important;
}

.tooltipClass.tooltip-left::after {
  border-color: transparent transparent transparent #545454 !important;
}

.tooltipClass.tooltip-right::after {
  border-color: transparent #545454 transparent transparent !important;
}

.tooltip-tabs {
  background-color: #005C72;
  color: white;
  font-size: 16px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  .tippy-arrow {
    color: #005C72;
  }
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
}

[hidden] {
  display: none !important;
}